// Colors
$local-topbar: #333333;
$local-topbar-text: #ffffff;
$local-bkg: #FFCC66;
$local-bkg-image: none;
$local-text: #333333;

// Font
$local-text-font: 'Open Sans', sans-serif;
$local-headline-font: 'Oswald', sans-serif;

// Dimensions
$local-topbar-height: 70px;
$local-logo-width: 280px;
$local-logo-margin-top: -30px;
$local-logo-margin-bottom: 20px;
