a,
a:active,
a:hover,
a:visited {
  color: $local-text !important;
  text-decoration: none;
  padding-bottom: rem-calc(4);
  -webkit-transition: border-bottom-color 500ms ease;
  -moz-transition: border-bottom-color 500ms ease;
  -ms-transition: border-bottom-color 500ms ease;
  -o-transition: border-bottom-color 500ms ease;
  transition: border-bottom-color 500ms ease;
  border-bottom-color: rgba(255, 255, 255, 0);
}

a:hover {
  border-bottom-width: 1px;
  border-bottom-color: $local-text;
  border-bottom-style: solid;
}

body {
  @if $local-bkg-image != none {
    background: url($local-bkg-image) repeat !important;
  }
}

.for-sale {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: $local-headline-font;
  font-size: rem-calc(22);
  color: $local-topbar-text !important;
  a,
  a:active,
  a:hover,
  a:visited {
    color: $local-topbar-text !important;
  }
  a:hover {
    border-bottom-color: $local-topbar-text !important;
  }
}

.hero {
  margin-top: 4%;
  .logo-gfx,
  .logo-text {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(400);
    float: none !important;
  }
  .logo-text {
    margin-top: $local-logo-margin-top;
    max-width: rem-calc($local-logo-width);
    width: rem-calc($local-logo-width);
  }
  h2 {
    font-family: $local-text-font;
    margin-left: auto;
    margin-right: auto;
    font-size: rem-calc(16);
    color: $local-text;
    max-width: rem-calc(300);
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -ms-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;
  }

  .description {
    margin-left: auto;
    margin-right: auto;
    font-size: rem-calc(12px);
    margin-top: rem-calc(10px);
    max-width: rem-calc($local-logo-width);
    width: rem-calc($local-logo-width);
    font-family: $local-text-font;
  }

  .fade {
    opacity: 1;
  }

  .clear {
    float: none;
    clear: both;
  }

}

.info-text {
  padding-top: $local-logo-margin-bottom;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: $local-text-font;
}

.ad {
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
